import styled, {css} from "styled-components";
import {RadioButtonWrapper} from "../ChallengeDetailsV2/ChallengeLeaderboardV2/styles";
import { CustomInput } from '../InputGoals/styles';
import Datetime from 'react-datetime';
const RegularFont = 'Rubik-Regular';

const TimeContainer = styled.div`
  width: 100%;
  margin-top: 15px;
`;
const CustomInputTime = styled.input`
  font-size: 20px;
  padding: 12px 10px;
  border-radius: ${({border}) => border ? '0px 4px 4px 0px' : '4px 0 0 4px'};
  border: 1px solid #cdcbcb;
  border-right: ${({border}) => border ? '1px solid #cdcbcb' : 'none'};
  border-left: ${({border}) => border ? 'none' : '1px solid #cdcbcb' };
  float: left;
  width: 90px;
  position: relative;
  height: 46px;
  text-align: center;
  color: rgb(153,153,153);
  &:hover, &:active, &:focus {
    outline: none;
  }
  @media(max-width: 500px){
    width: 20%;
  }
`;
const IngredientButtonContainer = styled.div`
  width: 25% !important;
  margin-top: 15px;
  @media (max-width: 1150px){
    width: 100% !important;
  }
`;
const AddButton = styled.div`
  width: 150px;
  height: 46px;
  background-color: #159fc9;
  border: 1px solid #159fc9;
  color: #fff;
  padding: 10px;
  text-align: center;
  border-radius: 6px;
  margin-left: 10px;
  cursor: pointer;
  font-size: 16px;
  @media (max-width: 1150px){
   margin: 10px auto;
  }
`;
const CustomInputIngredients = styled.input`
  margin-top: 15px;
  padding: 12px 10px;
  border: none;
  float: left;
  width: 100%;
  height: 46px;
  background: #F6F6F8;
  border-radius: 6px;

  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #67676A;

  @media (max-width: 1150px){
    width: 100%;
  }
  &:hover, &:active, &:focus {
    outline: none;
  }

  ${({ disabled }) => disabled === true && css`
    opacity: 0.5;
    cursor: not-allowed !important;
  `}
`;
const FieldTitleIngredients = styled.span`
letter-spacing: 0px;
line-height: 24px;
font-family: 'Rubik';
float: left;
margin-top: ${({ marginTop }) => marginTop && marginTop};
width: 100%;
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 22px;
color: #67676A;

.day {
  font-size: 18px;
  color: #159fc9;
}
`;

const ShowIngredientContent = styled.div`
  width: 100%;
  color: #000;
  background-color: #fff;
  display: flex;
  margin: 10px 0;
  align-items: center;
  flex-direction: column;
  >div{
    display: flex;
    align-items: center;
    width: 100%;
    > div{
      width: 75%;
      height: auto;
      border-radius: 6px;
      display: flex;
      align-items: center;
      font-family: ${RegularFont};
      font-size: 16px;
      border: 1px solid #cdcbcb;
      padding: 10px;
      flex-wrap: wrap;
      flex-basis: 75%
      background-color: #f3f3f3;
      @media (max-width: 1150px){
        width: 100%;
        flex-basis: 100%
      }
    }
     > i{
        float: right;
        margin-left: 5px;
        font-size: 30px;
        color: red;
        cursor: pointer;
      }
  }
`;
const ShowDirectionContent = styled.div`
  width: 100%;
  color: #000;
  background-color: #fff;
  display: flex;
  margin: 10px 0;
  align-items: center;
  flex-direction: column;
  >div: first-child{
    color: rgb(153,153,153);
    font-family: ${RegularFont};
    font-size: 15px;
    width: 100%;
  }
  >div: last-child{
    display: flex;
    align-items: center;
    width: 100%;
    > div{
      width: 75%;
      height: auto;
      border-radius: 6px;
      display: flex;
      align-items: center;
      font-family:${RegularFont};
      font-size: 16px;
      border: 1px solid #cdcbcb;
      padding: 10px;
      background-color: #f3f3f3;
      flex-wrap: wrap;
      flex-basis: 75%;
      @media (max-width: 1150px){
        width: 100%;
        flex-basis: 100%
      }
    }
     > i{
        float: right;
        margin-left: 5px;
        font-size: 30px;
        color: red;
        cursor: pointer;
      }
  }
`;
const MultipleImageContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  float: left;
  align-items: center;
  > img{
    width: 150px;
    height: 150px;
  }
`;
const ColonSeparator = styled.span`
  top: 8px;
  font-size: 20px;
  position: absolute;
  display: block;
  left: 85px;
`;
const RadioButtonContainer = styled(RadioButtonWrapper)`
   display: flex;
   padding: 20px 0 0 0;
   ${({justifyContent}) => justifyContent && css`
      justify-content: ${justifyContent};
  `}
   > div:first-child {
    @media (max-width:600px) {
      margin-bottom: 10px;
    }
   }
   @media (max-width:1040px) {
    flex-direction: column;
    align-items: flex-start;
    padding: 20px 0 0 0;
   }
`;
const NameContainer = styled.div`
  font-family: 'Rubik';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #67676A;
  float: left;
  padding-left: 10px;
  cursor: pointer;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  cursor: ${({ disabled }) => disabled && 'not-allowed !important'};

  width: 70%;
  height: 100%;
  padding-left: 0;
  display: flex;
  justify-content: left;
  align-items: center;
`;

const InviteAmigo = styled.div`
  margin: 10px;
  background: #FFFFFF;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;

  float: left;
  width: ${({width})=> width ? width : '180px'};
  height: 60px;
  position: relative;

  .checkboxDiv{
    display: flex;
    width: 30%;
    height: 100%;
    justify-content: center;
    align-items: center;
  }

  > img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    float: left;
    object-fit: cover;
  }
  
  label {
    padding-left: 10px;
    margin-top: -12px;
  }
  .showInfoText{
    display: none;
    box-shadow: 0px 4px 4px rgba(0,0,0,0.25);
    position: absolute;
    width: 270px;
    background: white;
    border-radius: 6px;
    height: 80px;
    z-index: 9999;
    top: -68px;
    left: 40px;
    padding: 10px;
    font-family: Rubik-Regular;
    &::after {
      width: 10px;
      height: 10px;
      background: #fff;
      position: absolute;
      content: '';
      bottom: -4px;
      left: 6%;
      transform: rotate(-45deg);
    }
    @media(max-width: 400px) {
      width: 250px;
    }
  }
  &:hover{
    .showInfoText{
      display: block;
    }
  }
  @media only screen and (max-width: 767px) {
    
    > img {
      margin-left: 15px;
    }
  }
`;
const InputContainer = styled.div`
  display: inline-block;
  width: ${({timeInput, widthInput}) => timeInput ? '100px' : widthInput ? '100%': '120px'} !important;
  margin-right: 10px;
  position: relative;
  margin-top: ${({ marginTop }) => marginTop && marginTop };
  cursor: ${({ disabled }) => disabled && 'not-allowed !important'};

  i {
    position: absolute;
    right: 10px;
    top: 17px;
    color: #999999;
    cursor: pointer;
    cursor: ${({ disabled }) => disabled && 'not-allowed !important'};
  }
  > div {
    width: ${({timeInput, widthInput}) => timeInput ? '100px' : widthInput ? '100%': '120px'} !important;
  }

  svg {
    top: 0;
    right: 0px;
    height: 42px;
    width: 15px;
    cursor: pointer;
    cursor: ${({ disabled }) => disabled && 'not-allowed !important'};
  }
  .rdtPicker {
    width: 100% !important;
    font-size: 12px;
    min-width: ${({ event }) => event ? '150px !important' : '300px'};
    cursor: ${({ disabled }) => disabled && 'not-allowed !important'};
  }
`;

const StyledDateTime = styled(Datetime)`
  > input {
    padding: 10px;
    margin-top: 0;
    height: 48px;
    color: rgb(153,153,153);
    border: 1px solid #cdcbcb;
    position: relative;
    background-color: white !important;
    font-size: 13px;
    cursor: pointer;
    padding-left: 40px;
    cursor: ${({ disabled }) => disabled && 'not-allowed !important'};
    
    &:hover, &:focus, &:active {
      outline: none;
      border-color: #cdcbcb;
      box-shadow: none;
    }
  }
  
  .rdt {
    >i {
      cursor: ${({ disabled }) => disabled && 'not-allowed !important'};
    }
  }
  .rdtPicker {
    width: 300px;
  }
  .rdtPicker td.rdtDisabled, .rdtPicker td.rdtDisabled:hover {
    cursor: pointer !important;
  }

  .rdtPicker td.rdtToday::before {
    bottom: 4px !important;
  }

  ${({ timeInput }) => timeInput && css`
    .rdtPicker {
      width: 150px;
    }
  `}
`;

const DateIconContainer = styled.div`
  float: left;
  position: absolute;
  top: 15px;
  right: 10px;
  width: 15px;
  cursor: pointer;
  line-height: 0px;
  cursor: ${({ disabled }) => disabled && 'not-allowed !important'};

  div{
    >img{
    width: 30px;
    min-height: 30px;
    margin-top: -8px;
    margin-left: 15px;
  }

  }

  >img{
    width: 30px;
    min-height: 30px;
    margin-top: -8px;
    margin-left: 15px;
  }
  > svg {
    cursor: ${({ disabled }) => disabled && 'not-allowed !important'};
    top: ${({ noTop }) => noTop && '0px'};
  }
  .rdt {
    .far fa-clock {
      cursor: ${({ disabled }) => disabled && 'not-allowed !important'}; 
    }
  }
`;

const PageTitle = styled.div`
  letter-spacing: 1px;
  margin-bottom: 35px;
  margin-left: 30px;
  display: inline-block;
  float: left;
  margin-top: 0;
  padding-left: 0;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 22px;
  color: #2C405A;
`;

const FieldTitle = styled.span`
  letter-spacing: 0px;
  font-family: 'Rubik';
  float: left;
  margin-top: ${({ marginTop }) => marginTop && marginTop};
  width: ${({ fullWidth }) => fullWidth && fullWidth};
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #67676A;

  .day {
    font-size: 18px;
    color: #159fc9;
  }
`;

const StyledInput = styled(CustomInput)`
  float: left;
  margin-top: 10px;
  height: 47px;
  font-size: ${({ locationInput }) => locationInput ? '14px' : '16px'};
  padding: 0 30px 0 20px;;
  cursor: ${({ disabled }) => disabled && 'not-allowed !important'};
  background: #F6F6F8;
  border-radius: 6px;
  border: none;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #67676A;
  
  &:focus, &:active {
    outline: none;
  }

  ::placeholder {
    color: rgb(153,153,153);
    opacity: 1;
  }

  :-ms-input-placeholder {
    color: rgb(153,153,153);
  }

  ::-ms-input-placeholder {
    color: rgb(153,153,153);
  }
`;

const ImageInput = styled.label`
  position: relative;
  cursor: pointer;
  margin-top: 10px;
  cursor: ${({ disabled }) => disabled && 'not-allowed !important'};
  padding: 17px;
  ${'' /* height: 100%; */}
  width: 27%;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 1.6px;
  text-transform: uppercase;
  color: #FFFFFF;
  background: linear-gradient(0deg, #4798D9, #4798D9);
  border-radius: 25px;
  
  > input {
    opacity: 0;
    z-index: -1;
    position: absolute;
    bottom: ${({imageReload}) => imageReload && '0'};
  }
  
  ${(props) => props.showAtBottom && css`
    position: absolute;
    left: calc(40% + 20px);
    bottom: 0;

    @media (max-width: 500px) {
      left: 0;
      position: static;
    }
  `}
`;

const SaveButton = styled.button`
  white-space: nowrap;
  padding: ${({ addMargin }) => addMargin ? '0' : '7px 10px'};
  width: ${({ addMargin }) => addMargin ? '158px' : 'auto'};
  height: ${({ addMargin }) => addMargin ? '35px' : 'auto'};
  margin-top: 10px;
  margin-bottom: ${(props) => props.addMargin ? '55px' : '0'};
  float: ${(props) => props.float ? 'right' : 'left'};
  border: none;
  background: linear-gradient(0deg, #4798D9, #4798D9);
  border-radius: 25px;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 1.6px;
  text-transform: uppercase;
  color: #FFFFFF;

  &:focus, &:active, &:hover {
    outline: none;
  }
  
  > i {
    margin-right: 5px;
  }

  ${({ disabled }) => disabled === true && css`
    opacity: 0.5;
    cursor: not-allowed !important;
  `}
  
  @media only screen and (max-width: 767px) {
    margin-bottom: 15px;
  }
  
  ${({ addMargin }) => addMargin && css`
    @media (max-width: 500px) {
      margin-bottom: 5px;
    }
  `}
`;

const TopContainer = styled.div`
  padding-bottom: 16px;
  float: left;
  width: 100%;
  .main{
    display: inline-block;
    width: 60%;
    >span{
      font-size: 12px;
    }
  }
  .feature{
    display: inline-block;
    width: 40%;
  }
`;

const CheckMark = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  background-color: white;
  border-radius: 50%;
  border: 1px solid #cdcbcb;
  cursor: ${({ disabled }) => disabled && 'not-allowed !important'};

  &::after {
    content: "";
    position: absolute;
    display: block;
    left: 8px;
    top: 2px;
    width: 6px;
    height: 13px;
    border-style: solid;
    border-color: ${({ checked }) => checked ? '#159fc9' : 'white'};
    border-width: 0 1px 1px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    border-top-color: transparent;
    border-left-color: transparent;
    cursor: ${({ disabled }) => disabled && 'not-allowed !important'};
  }
`;

const StyledPassword = styled.div`
  margin-top: 10px;
  >input{
    background: #F6F6F8;
    border-radius: 6px;
    width: 100%;
    padding: 15px 0 15px 20px;
    border: none;
  }
  >i{
    cursor: pointer;
    position: absolute;
    right: 30px;
    top: 16px;
  }
  .pass{
    color: red
  }
`

const ClientAnniversary = styled.div`
  float: left;
  width: 100%;
  padding: 30px 38px 30px 40px;
  border-bottom: 1px solid #e2e2e4;
  >div:first-child{
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #67676A;
  }
  >div:nth-child(2){
    margin-top: 2px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #4798D9;
  }
  >div:last-child{
    margin-top: 5px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 24px;
    color: #67676A;
  }
`

const ScrollableContainer = styled.div`
  height: auto;
  float: left;
  width: 100%;
  padding: 20px 5px 20px 0;
  overflow-y: auto;
  @media (max-width: 550px) {
    padding: 10px 5px 10px 0;
  }

  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background: #403e3f;
    cursor: pointer;
    border-radius: 2.5px;
  }

  ::-webkit-scrollbar-track {
    background: #c3c2c2;
    border-radius: 2.5px;
  }
`;

const FormContainer = styled.div`
  width: ${({ editChallenge }) => editChallenge ? '100%' : '65%'};
  float: left;
  display: block;
  background-color: white;
  box-shadow: 1px 1.732px 46px rgba(0,0,0,0.18);

  .calendar{
    display: flex;
    float: left;
  }
  
  > div > ul {
    margin-top: 20px;
  }
  
  @media only screen and (max-width: 767px) {
   width: 100%;
   margin-bottom: 20px;
   margin-top: 20px;
  }
  > h3 {
    font-size: 18px;
    padding: 32px 0 10px 35px;
    margin: 0;
    
    @media (max-width: 500px) {
      font-size: 20px;
      padding: 20px 10px 0 10px;
    }
  }
`;

export {CustomInputTime, DateIconContainer, StyledDateTime, InputContainer, TimeContainer,InviteAmigo, NameContainer, AddButton, CustomInputIngredients, FieldTitleIngredients, IngredientButtonContainer, ShowIngredientContent, ShowDirectionContent, MultipleImageContainer, ColonSeparator, RadioButtonContainer, PageTitle, FieldTitle,
  StyledInput, ImageInput, SaveButton, TopContainer, CheckMark, StyledPassword, ClientAnniversary, ScrollableContainer, FormContainer
  
};